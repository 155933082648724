import { useStaticQuery, graphql } from 'gatsby'

// Fetch option pages
export const useProducts = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query ProductContent {
        wordpressAcfOptions(wordpress_id: { eq: "acf" }) {
          wordpress_id
          options {
            products {
              weight
              title
              price
              excerpt
              image {
                id
                alt_text
                caption
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              thumbnail {
                id
                alt_text
                caption
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 150) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wordpressAcfOptions.options.products
}

export default useProducts
