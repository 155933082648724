import React from 'react'
import useProducts from '@hooks/use-products'
import Hero from '@components/Hero'
import Container from '@components/Container'
import Footer from '@components/Footer'
import SEO from '@components/partials/SEO'
import Image from '@partials/Image'
import styled from 'styled-components'
import TextComponent from '@components/page/TextComponent'
import { tokens, spacing, box, media } from '@tokens'

export const $Store = styled.main`
  ${box({
    spacing: 'horizontal',
  })};
  ${box({
    spacing: 'bottom',
  })};
`

export const $Products = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${tokens.get('maxWidth.base')};
  width: 100%;

  ${media.greaterThan('xl')`
    max-width: ${tokens.get('maxWidth.wide')};
  `}
`

export const $Product = styled.div`
  margin-top: ${spacing(10)};

  ${media.greaterThan('m')`
    display: grid;
    grid-template-columns: auto ${spacing(6)} 20rem;
    grid-template-rows: 100%;
  `}

  &:nth-child(even) {
    direction: rtl;
  }

  &:first-child {
    margin-top: 0;
  }
`

export const $ProductImage = styled.div`
  position: relative;
  flex: 1 1 50%;
  border-radius: 0.25rem;
  overflow: hidden;
  grid-column: 1 / 3;
  grid-row: 1 / -1;
  z-index: 5;

  &::before {
    content: '';
    display: block;
    padding-bottom: 75%;
    width: 100%;
  }

  .gatsby-image-wrapper,
  figure,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`
export const $ProductContent = styled.div`
  position: relative;
  flex: 1 1 50%;
  grid-column: 2 / -1;
  grid-row: 1 / -1;
  z-index: 10;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};
  margin-top: ${spacing(-2)};

  ${media.greaterThan('s')`
    padding-left: ${spacing(4)};
    padding-right: ${spacing(4)};
    margin-top: ${spacing(-4)};
  `}

  ${media.greaterThan('m')`
    padding: ${spacing(4)} 0;
    margin-top: 0;
  `}
`

export const $ProductContentInner = styled.div`
  background: ${tokens.brand('lightAlt')};
  padding: ${spacing(4)};
  border-radius: 0.25rem;
  overflow: hidden;
  background: ${tokens.brand('lightAlt')};
  padding: ${spacing(4)};
  width: 100%;
  direction: ltr;

  button {
    margin-top: ${spacing(4)};
  }
`

export const $ProductContentHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing(3)};

  h3 {
    margin-bottom: 0;
  }

  .price {
    font-style: italic;
  }
`

export const $ProductsDisclaimer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing(3)};
  max-width: ${tokens.get('maxWidth.base')};
  width: 100%;

  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};

  ${media.greaterThan('s')`
    padding-left: ${spacing(4)};
    padding-right: ${spacing(4)};
  `}

  ${media.greaterThan('m')`
    padding-left: 0;
    padding-right: 0;
  `}

  ${media.greaterThan('xl')`
    max-width: ${tokens.get('maxWidth.wide')};
  `}

  p {
    font-size: 0.75rem;
    max-width: 20rem;
  }
`

const Store = () => {
  const products = useProducts()

  const page = {
    designations: ['is404'],
  }
  const hero = {
    heading: 'Swag Store',
    content:
      'Looking to join the crew? Or simply just represent? Either way, with our gear your going to look good.',
  }
  return (
    <>
      <SEO title="Store" description="Store" />
      <Container page={page}>
        <Hero {...hero} {...page} />
        <$Store>
          <$Products>
            {products.map((product, index) => {
              const {
                title,
                excerpt,
                image,
                thumbnail,
                price,
                weight,
              } = product
              return (
                <$Product key={title}>
                  <$ProductImage>
                    <Image
                      key={image.localFile.publicURL}
                      source={image}
                      className="image"
                    />
                  </$ProductImage>
                  <$ProductContent>
                    <$ProductContentInner>
                      <$ProductContentHeader>
                        <h3>{title}</h3>
                        <div className="price">${price}</div>
                      </$ProductContentHeader>
                      <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                      <button
                        className="snipcart-add-item button"
                        data-item-id={`GBOX${index}`}
                        data-item-price={price}
                        data-item-url={'/store'}
                        data-item-weight={weight}
                        data-item-description={`${excerpt
                          .replace(/(<([^>]+)>)/gi, '')
                          .substring(0, 75)
                          .replace(/[\.!\?,;:\s]*$/, '')}...`}
                        data-item-name={title}
                        data-item-image={thumbnail.localFile.publicURL}
                      >
                        Add to cart
                      </button>
                    </$ProductContentInner>
                  </$ProductContent>
                </$Product>
              )
            })}
          </$Products>
          <$ProductsDisclaimer>
            <p>
              We gratefully acknowledge the financial support of the Province of
              British Columbia through the Ministry of Jobs, Economic Recovery
              and Innovation.
            </p>
          </$ProductsDisclaimer>
        </$Store>
        <Footer />
      </Container>
    </>
  )
}

export default Store
